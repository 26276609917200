import React from "react"
import { graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import newsStyles from "./newsPage.module.scss"
import Layout from "../components/layout"
import Head from "../components/head"
import HeaderImg from "../components/imgs/headerImg"

export const query = graphql`
  query($slug: String!) {
    contentfulNews(slug: { eq: $slug }) {
      id
      slug
      title
      description
      date(formatString: "MMMM Do, YYYY")
      body {
        json
      }
      cover {
        localFile {
          childImageSharp {
            fluid(maxHeight: 1200, maxWidth: 1200) {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`

const NewsPage = props => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return (
          <h2 key={children} className="subtitle is-4">
            {children}
          </h2>
        )
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return (
          <h1 key={children} className="title">
            {children}
          </h1>
        )
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <h3 key={children} className="subtitle is-4">
            {children}
          </h3>
        )
      },
    },
  }

  return (
    <Layout>
      <Head title={props.data.contentfulNews.title} />
      <section className="hero ">
        <div className="hero-body">
          <div className="container">
            <HeaderImg data={props} model="contentfulNews" />

            <section className="section">
              <div className="columns">
                <div className="column is-8 is-offset-2">
                  <div className="content is-medium">
                    {documentToReactComponents(
                      props.data.contentfulNews.body.json,
                      options
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NewsPage
